body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

input
{
  width: 40%;
  height: 50px;
  border-color: green;
  margin-bottom: 20px;
}

.userbox{
  width: 70;
}

.chat
{
  display: block;
  flex-direction: column;
  align-items: center;
  border: 1px solid grey;
  /*width: 60%;*/
  width: auto;
  /*max-width: 80%;
  justify-content: space-evenly;*/
  margin: 30px;
}


.chat-header
{
  background-color: gray;
  width: 100%;
  text-align: center;
}

.chat-footer
{
  width: 100%;
  display: flex;
  align-self: flex-end;
  border: 1px solid grey;
  height: 30px;
}

.chat-footer input
{
  width: 90%;
  height: 95%;
  border: none;
}

.chat-footer button
{
  border: 1px solid grey;
  height: 100%; 
  background-color: lightgreen;
  border-radius: 0;
}

.chat-body
{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 500px;
  
}

.scroll
{
  width: 100%;
  height: 100%;
}

.scrollbar
{
  overflow-x: hidden;
}

#message
{
   display: flex;
   width: 100%;
}

.you .message-content
{
  padding: 5px;
  max-width: 70%;
  background-color: rgb(103, 224, 103);
  margin: 10px;
  border-radius: 15px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.other .message-content
{
  padding: 5px;
  max-width: 70%;
  background-color:rgb(182, 180, 180);
  margin: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.message-content
{
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.you 
{

  justify-content: flex-end;
}

.other
{
  justify-content: flex-start;

}

#message h3
{
  margin-left: 5px;
}

  .name
{
border-bottom: 1px solid grey;
width: fit-content;
}

.message-content h5 
{
  text-align: end;
}


.auth
{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  width: 80%;
  margin: 100px auto;
  justify-content: space-evenly;
  border: 1px solid grey;
}

.input
{
  width: 65%;
  height: 35px;
  border-radius: 2px;
  background-color: transparent;
  padding: 2px;
  border: none;
  border-bottom: 1px solid grey;
}

.invalid
{
  width: 65%;
  height: 35px;
  border-radius: 2px;
  background-color: salmon;
  padding: 2px;
  border: none;
  border-bottom: 1px solid red;
}

.btn-danger
{
  background-color: rgb(204, 29, 29);
  color: white;
  border: none;
  border-radius: 5px;
  
}


.navlinks a
{
  text-decoration: none;
  color: gray;
  font-size: larger;
  padding: 5px;
}

.navlinks a.active
{
  border-bottom: 1px solid black;
  color: black;
}

.user
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  flex-grow: 1;
  margin: 5px;
}

.userContainer
{
  margin-bottom:30px;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  width: auto;
  overflow-x: auto;
}

.totalContainer
{

  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
}

@media only screen and (max-width : 850px)
{


  .userbox{
    width: 110;
  }
  .chat h3
  {
    font-size: 16px;
  }

  .chat h5
  {
    font-size: 10px;
  }

  .userContainer
{
  margin-bottom:30px;
  margin-left: 30px;
  margin-right: 30px;
}

.chat
{
  width: 80%;
  max-width: 85%;
}

}

@media only screen and (max-width : 730px)
{

  .userbox{
    width: 110;
  }

  .message-content
  {
    max-width: 90%;
  }

  .chat
  {
    width: auto;
    max-width: 100%;
  }

  .userContainer
{
  margin-bottom:20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

  .chat h3
  {
    font-size: 14px;
  }

  .chat h5
  {
    font-size: 10px;
  }


.user h3
{
  font-size: 14px;
}

.user h4
{
  font-size:8px;
}

  .message-content
  {
    max-width: 90%;
  }

  .chat
  {
    max-width: 95%;
  }

}


@media only screen and (max-width : 510px)
{

  .totalContainer
  {
    width: 100%;
  }

  .message-content
  {
    max-width: 95%;
  }

  .chat
  {
    max-width: 100%;
  }

  .userContainer
  {
    margin:5px;
   
  }

  .userContainer h3
  {
     font-size: 10px;
  }

  .userContainer h4
  {
     font-size: 7px;
  }

  .userContainer h5
  {
    font-size: 7px;
  }

  .chat h3
  {
    font-size: 13px;
  }

  .chat h5
  {
    font-size: 8px;
  }

  .userContainer
  {
      max-width: 100%;
      padding-top: 20px;
      padding-bottom: 40px;
      background-color: white;
  }

  .user
  {
    width:70px;
    height: 70px;
  }

.user h3
{
  font-size: 11px;
}

.user h4
{
  font-size: 9px;
}

  .chat
  {
    width: 90%;
    max-width: 98%;
  }


}


.signuppagemain{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment:scroll;
  opacity: 0.8;
  background-image: url(https://images.unsplash.com/photo-1551842864-a185da0fbbbb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGNoYXR0aW5nfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=600&q=60);
}


.loginpagemain{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment:scroll;
  opacity: 0.8;
  background-image: url(https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjN8fGNoYXR0aW5nJTIwb25saW5lfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=600&q=60);
}