/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/
/* body {
    background-color: #74EBD5;
    background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);
  
    min-height: 100vh;
  } */

  /* .people{
    width: 350px;
  } */


  .maincon{
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding:0 !important;
    margin: 0 !important;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    width: 5px;
    background: #f5f5f5;
  }
  
  ::-webkit-scrollbar-thumb {
    width: 1em;
    background-color: #ddd;
    outline: 1px solid slategrey;
    border-radius: 1rem;
  }
  
  .text-small {
    font-size: 0.9rem;
  }
  
  .messages-box,
  .chat-box {
    height: 400px;
    overflow-y: scroll;
  }
  
  /* .rounded-lg {
    border-radius: 0.5rem;
  } */
  
  input::placeholder {
    font-size: 0.9rem;
    color: #999;
  }